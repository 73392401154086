import {
  DashboardData,
  DashboardParsed,
} from '@/app/core/models/dashboard.model';

export const dashboardData: DashboardData = [
  { Key: 'ALLBARGES', Value: '2714' },
  { Key: 'LOADED', Value: '0' },
  { Key: 'EMPTY', Value: '614' },
  { Key: 'MOVING', Value: '727' },
  { Key: 'STANDBY', Value: '1994' },
  { Key: 'PLACEDORIG', Value: '115' },
  { Key: 'PLACEDDEST', Value: '282' },
  { Key: 'ETAORIG', Value: '0' },
  { Key: 'ETADEST', Value: '0' },
  { Key: 'ETA', Value: '0' },
  { Key: 'FILTERTITLE', Value: 'All Barges' },
  { Key: 'DEMORIGOVERFREE', Value: '5' },
  { Key: 'DEMORIGTOTAL', Value: '7' },
  { Key: 'DEMDESTOVERFREE', Value: '0' },
  { Key: 'DEMDESTTOTAL', Value: '0' },
  { Key: 'DEMALLOVERFREE', Value: '1' },
  { Key: 'DEMALLTOTAL', Value: '1' },
];

export const buildDashboard = (): DashboardParsed => ({
  ALLBARGES: '',
  LOADED: '',
  EMPTY: '',
  MOVING: '',
  STANDBY: '',
  PLACEDORIG: '',
  PLACEDDEST: '',
  ETAORIG: '',
  ETADEST: '',
  ETA: '',
  FILTERTITLE: '',
  DEMORIGOVERFREE: '',
  DEMORIGTOTAL: '',
  DEMDESTOVERFREE: '',
  DEMDESTTOTAL: '',
  DEMALLOVERFREE: '',
  DEMALLTOTAL: '',
});
